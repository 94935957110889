import gql from 'graphql-tag'

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword ($email: String!) {
    resetPassword (email: $email)
  }
`

export const VALIDATE_TOKEN = gql`
  mutation validateToken ($token: String!) {
    validateToken (token: $token) {
      valid
    }
  }
`

export const CREATE_DEALERSHIP = gql`
  mutation createDealership ($brandId: String!, $companyId: Int!, $name: String!, $phone: String!, $email: String!) {
    createDealership (brandId: $brandId, companyId: $companyId, name: $name, phone: $phone, email: $email) {
      name
    }
  }
`

export const EDIT_DEALERSHIP = gql`
  mutation editDealership ($brandId: String, $companyId: Int, $name: String, $email: String, $phone: String, $dealershipId: Int!, $remove: Boolean) {
    editDealership (brandId: $brandId, companyId: $companyId, name: $name, email: $email, phone: $phone, dealershipId: $dealershipId, remove: $remove) {
      name
      email
      phone
      brands
    }
  }
`

export const ADD_CUSTOMER_TO_DEALERSHIP = gql`
  mutation addCustomerToDealership ($userEmail: String!, $userPhone: String!, $userName: String!, $userPid: String, $dealershipId: Int!, $orderNr: String!, $customerId: String!, $address: String) {
    addCustomerToDealership (userEmail: $userEmail, userPhone: $userPhone, userName: $userName, userPid: $userPid, dealershipId: $dealershipId, orderNr: $orderNr, customerId: $customerId, address: $address) {
      name
    }
  }
`

export const CREATE_USER = gql`
  mutation createUser ($name: String!, $email: String!, $pid: String, $phone: String!, $dealership: Int) {
    createUser (name: $name, email: $email, pid: $pid, phone: $phone, dealership: $dealership) {
      name
      email
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser ($id: Int! $name: String, $phone: String, $dealershipId: Int, $address: String, $remove: Boolean, $password: String) {
    updateUser (id: $id, name: $name, phone: $phone, dealershipId: $dealershipId, address: $address, remove: $remove, password: $password) {
      name
      email
    }
  }
`


export const CREATE_ORDER = gql`
  mutation createOrder ($customer: Int!, $dealership: Int!) {
    createOrder (customer: $customer, dealership: $dealership) {
      id
      status
      description
    }
  }
`

export const DELETE_ORDER = gql`
  mutation deleteOrder ($orderId: Int!) {
    deleteOrder (orderId: $orderId)
  }
`

export const FIND_PRODUCT = gql`
  mutation findProduct ($prodId: String!, $dealershipId: Int!, $customerId: Int!) {
    findProduct (prodId: $prodId, dealershipId: $dealershipId, customerId: $customerId) {
      name
      sku
      brand
      quantity
      stock
      price
      discount
      unit
      status
      deliveryDate
      description
    }
  }
`

export const ADD_ORDER_ITEM = gql`
  mutation addOrderItem ($item: OrderItemInput!, $orderId: Int!, $split: Boolean) {
    addOrderItem (item: $item, orderId: $orderId, split: $split) {
        id
    }
  }
`

export const REMOVE_ORDER_ITEM = gql`
  mutation removeOrderItem ($itemId: Int!) {
    removeOrderItem (itemId: $itemId)
  }
`

export const UPDATE_ORDER_ITEM = gql`
  mutation updateOrderItem ($itemId: Int!, $description: String, $quantity: Int) {
    updateOrderItem (itemId: $itemId, description: $description, quantity: $quantity)
  }
`

export const SUBMIT_ORDER = gql`
  mutation submitOrder ($orderId: Int!, $delivery: String!) {
    submitOrder (orderId: $orderId, delivery: $delivery)
  }
`

export const NEW_USER_MUTATION = gql`
  mutation RegisterUser($email: String!) {
    registerUser(email: $email) {
      user {
        name
        phone
        email
      }
    }
  }
`

export const NEW_DEALERSHIP = gql`
  mutation RegisterDealership(
    $brandId: String!
    $orderId: String!
    $dealershipId: String!
    $mainContactId: String!
    $email: String!
  ) {
    registerDealership(
      brandId: $brandId
      orderId: $orderId
      dealershipId: $dealershipId
      mainContactId: $mainContactId

      email: $email
    ) {
      brandId
      orderId
      users {
        name
        email
      }
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation createCustomer ($dealershipId: Int!, $name: String!, $address: String, $phone: String, $email: String, $customerId: String!, $orderNr: String!) {
    createCustomer (dealershipId: $dealershipId, name: $name, address: $address, phone: $phone, email: $email, customerId: $customerId, orderNr: $orderNr)
  }
`

export const ADD_USER_TO_CUSTOMER = gql`
  mutation addUserToCustomer ($userEmail: String!, $userPhone: String!, $userName: String!, $userPid: String, $customerId: Int!) {
    addUserToCustomer (userEmail: $userEmail, userPhone: $userPhone, userName: $userName, userPid: $userPid, customerId: $customerId)
  }
`

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer ($id: Int! $name: String, $phone: String, $email: String, $address: String, $remove: Boolean) {
    updateCustomer (id: $id, name: $name, phone: $phone, email: $email, address: $address, remove: $remove) {
      name
      email
      phone
      address
      id
    }
  }
`

export const REMOVE_CUSTOMER = gql`
  mutation removeCustomer ($customerId: Int!, $dealershipId: Int!) {
    removeCustomer (customerId: $customerId, dealershipId: $dealershipId)
  }
`

export const REMOVE_CUSTOMER_USER = gql`
  mutation removeCustomerUser ($userId: Int!, $customerId: Int!, $dealershipId: Int!) {
    removeCustomerUser (userId: $userId, customerId: $customerId, dealershipId: $dealershipId)
  }
`

export const UPDATE_USER_PASS = gql`
  mutation changeUserPassword ($userId: Int!, $newPassword: String!) {
    changeUserPassword (userId: $userId, newPassword: $newPassword)
  }
`