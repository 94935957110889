import React, { useEffect, useRef, useState } from 'react'
import './Orders.scss'
import { GET_ORDERS } from '../../graphql/queries'
import { useMutation, useQuery } from '@apollo/client'
import { useLocation, useNavigate } from 'react-router'
import EmptyResult from '../../components/EmptyResult/EmptyResult'
import { useAuth } from '../../hooks/useAuth'
import Pagination from '../../components/Pagination/Pagination'
import { CREATE_ORDER } from '../../graphql/mutations'
import { useTranslation } from 'react-i18next'

const initialOrderBy = 'createdAt'

const Orders = () => {

  const location = useLocation()
  const navigate = useNavigate()
  const { logout, user, currentDealership, currentCustomer } = useAuth()
  const page = useRef(0)
  const limit = useRef(10)
  const [orderBy, setOrderBy] = useState(initialOrderBy)
  const [sort, setSort] = useState('DESC')

  const { t } = useTranslation() 
  
  const [createOrder] = useMutation(CREATE_ORDER)
  const { data, refetch, error } = useQuery(GET_ORDERS, {
    variables: {
      page: page.current,
      limit: limit.current,
      dealership: currentDealership ? currentDealership.id : null,
      customer: currentCustomer ? currentCustomer.id : null,
      orderBy,
      sort,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

  const tableHeaders = [
    {
      label: t('Order nr'),
      key: 'id',
    },
    {
      label: t('Customer name'),
      key: 'customer',
    },
    {
      label: t('Created Date'),
      key: 'createdAt',
    },
    {
      label: t('Sent Date'),
      key: 'updatedAt',
    },
    {
      label: t('Status'),
      key: 'status',
    },
  ]

  useEffect(() => {
    refetch()
  }, [location])

  useEffect(() => {
    if (error && error.message.includes('Invalid credentials')) {
      return logout()
    }
  }, [error])

  const addOrder = async () => {
    try {
      const res = await createOrder({
        variables: {
          dealership: currentDealership?.id,
          customer: currentCustomer?.id,
        }
      })
      if (res && res.data?.createOrder && res.data.createOrder.id) {
        navigate(`/order/${res.data.createOrder.id}`, { replace: true })
      }
    } catch (err) {
      console.log('Error creating order', err)
    }
  }

  const getColValue = (value, key, item) => {
    switch (key) {
      case 'items':
        return `${value.length}`
      case 'status':
        return getOrderStatus(value)
      case 'createdAt':
        return (new Date(+value)).toLocaleDateString('et', {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit',
        })
      case 'customer':
        return item.Customer && item.Customer.name ? item.Customer.name : ''
      case 'updatedAt':
        if (['Sent', 'Invoiced'].includes(item.status)) return ''
        return (new Date(+value)).toLocaleDateString('et', {
          month: '2-digit',
          year: 'numeric',
          day: '2-digit',
        })
    }
    return value
  }

  const getOrderStatus = (status) => {
    switch (status) {
      case 'Draft':
        return t('Draft')
      case 'Sent':
        return t('Ordered')
      case 'Invoiced':
        return t('Invoiced')
    }
  }

  const getPreviousPage = () => {
    page.current = page.current - 1
    refetch({
      page: page.current,
      limit: limit.current,
    })
  }

  const getNextPage = () => {
    page.current = page.current + 1
    refetch({
      page: page.current,
      limit: limit.current,
    })
  }

  const handleSort = (key) => {
    const newSort = sort === 'ASC' ? 'DESC' : 'ASC'

    refetch({
      page: page.current,
      limit: limit.current,
      dealership: currentDealership ? currentDealership.id : null,
      orderBy: key === 'user' ? 'user.name' : key,
      sort: newSort,
    })
    
    setSort(newSort)
    setOrderBy(key)
  }

  return (
    <div className="view orders-view">
      <div className="inner">
        <div className="view-title">
          <h1>{ t('Orders') }</h1>
          { user && user.role === 'User' &&
            <button className="btn" onClick={(e) => {
              e.preventDefault()
              addOrder()
            }}>{ t('Create new order') }</button>
          }
        </div>
        { data && data.getOrders && data.getOrders.rows && data.getOrders.rows.length > 0 ?
          <div className="view-table">
            <div className="view-table--header" style={{
              gridTemplateColumns: `repeat(${tableHeaders.length}, 1fr)`
            }}>
              { tableHeaders.map(x => 
                (
                  <div
                    key={x.label}
                    onClick={() => handleSort(x.key)}
                    className={`view-table--header-item ${'view-table--header-item-' + x.key} ${orderBy === x.key ? 'view-table--header-sort-' + sort.toLowerCase() : ''}`}
                  >
                    <span>{ x.label }</span>
                  </div>
                ) 
              )}
            </div>
            <div className="view-table--content">
              { data.getOrders.rows.map((x, index) => (
                <div
                  key={x.id}
                  className="view-table--content-row"
                  style={{
                    gridTemplateColumns: `repeat(${tableHeaders.length}, 1fr)`
                  }}
                  onClick={() => navigate(`/order/${x.id}`)}
                >
                  { tableHeaders.map(i => (
                    <div
                      key={`${x.id}-${i.key}-${index}`}
                      className={`view-table--content-item ${'view-table--content-item-' + i.key}`}
                    >
                      <span>{ getColValue(x[i.key], i.key, x) }</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <Pagination total={data.getOrders.count} onPrev={getPreviousPage} onNext={getNextPage} current={page.current} limit={limit.current} />
          </div>
          :
          <EmptyResult message={ t('There are currently no orders') } />
        }
      </div>
    </div>
  );
};

export default Orders;
